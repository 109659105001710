<template>
	<HeadingX
		class="c-base-h1"
		:class="{
			'c-base-h1--small': small,
			'c-base-h1--balance': balance,
		}"
		v-bind="{ tag }"
		:level-offset="levelOffset"
	>
		<template v-if="domProps.textContent">
			{{ domProps.textContent }}
		</template>
		<template v-else>
			<slot></slot>
		</template>
	</HeadingX>
</template>

<script>
import HeadingX from '~/components/shared/HeadingX';

export default {
	name: 'BaseH1',
	components: { HeadingX },

	props: {
		tag: {
			type: String,
			default: undefined,
		},
		levelOffset: {
			type: [Number, String],
			default: 0,
		},
		small: {
			type: Boolean,
			default: false,
		},
		balance: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		domProps() {
			const { domProps } = this.$vnode?.data || {};
			return domProps || {};
		},
	},
};
</script>

<style lang="postcss">
:where(.c-base-h1) {
	@apply text-h1 font-bold whitespace-pre-wrap;
}
:where(.c-base-h1--balance) {
	text-wrap: balance;
}
:where(.c-base-h1--small) {
	@apply text-h1-sm;
}
</style>
